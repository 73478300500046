.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.menu-top {
  margin:3px;
}

#frame1 {
  top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #464646b8;
  z-index: 9999;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
100% {
  transform: rotate(360deg);
}
}

.frame1 {
  top:0; left:0; bottom:0; right:0; width:100%; border:none; margin:0; padding:0; overflow:hidden;
}

.modalStyle {
  max-width:90%;  
}

.bg-cgu {
  background-color: #ffa116;
  color: #fff;
  font-weight: bold;
}

@media (max-width: 768px) {
  .modalStyle {
    /* width:100% !important; */
    max-width: 100% !important;   
  }
  .link-img {
    width:30% !important;
  }
  .cgu-fun {
    height:auto !important;
  }
}

@media (max-width: 992px) {
  .link-img {
    width:15% !important;
  }
  .cgu-fun {
    height:auto !important;
  }
 
}

.tag-name {
  position:absolute; 
  top: 10px;
  left: 10px;
}

.link-name {  
  padding:15px;
}

.link-img {
  width:50%;
}

.list-group {
  border-radius: 0px;
}

.accordion-button:not(.collapsed)::after {  
  background-image: var(--bs-accordion-btn-icon);
  filter: invert(69%) sepia(31%) saturate(5980%) hue-rotate(0deg) brightness(103%) contrast(102%);
}

.bi-favo {
  margin-right:2px;
  cursor:pointer;
}
.bi-favo-fill {
  margin-right:2px;  
}

#courseDetail table tbody td  {
  white-space: pre-wrap;
}

@media print {
  .card-br {page-break-after: always;}
}